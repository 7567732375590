<template>
<div style="display:flex;">
  <NewBaseSelect v-bind="$attrs" v-on="$listeners" style="flex:1"></NewBaseSelect>
  <!-- <supplier-select v-bind="$attrs" v-on="$listeners" style="flex:1"></supplier-select> -->
  <el-tooltip class="item" effect="dark" content="同步EAS信息" placement="top">
      <span class="set" @click="synchronize" v-if="!$attrs.disabled">
      <i class="iconfont icontongbu"></i>
    </span>
  </el-tooltip>
</div>

</template>
<script>
import NewBaseSelect from '@/components/common/base-select/new-base-select'
// import supplierSelect from '@/components/packages/supplier-select/supplier-select-psbc'
import { psbcApi } from '@/api/psbcApi'
export default {
  props: {
    supplierData: Object,
    companyData: Object
  },
  data () {
    return {}
  },
  components: {
    NewBaseSelect
    // supplierSelect
  },
  computed: {
    api () {
      return psbcApi
    }
  },
  methods: {
    synchronize (data) {
      if (this.$attrs.value) {
        const params = {
          creditCode: this.supplierData.creditCode,
          enterpriseId: this.supplierData.keyId,
          gysName: this.supplierData.enterpriseName,
          latestTime: this.companyData.syncTime
        }
        console.log(params)
        this.api.syncPreAuthorizationData(params).then(res => {
          this.success('同步成功')
          this.$emit('syncSuccess', this.supplierData)
        })
      } else {
        this.warning('请选择企业')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.set {
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  display: inline-block;
  background: linear-gradient(90deg, #285ED7 0%, #749EFF 100%);
  border-radius: 50%;
}
</style>
