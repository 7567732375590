import Rest from '@/utils/http/Rest'
import http from '@/utils/http'

export default class Product extends Rest {
  /**
     * 获取表格列表数据
     * @param data
     * @returns {AxiosPromise}
     */
  preCreditPage (data) {
    return http({
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/preCreditPage`,
      showLoading: true,
      data
    })
  }

  /**
   * 获取授权供应商列表
   * @param params
   * @returns {AxiosPromise}
   */
  getSupplierList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getSupplierList`,
      showLoading: true,
      params
    })
  }

  /**
   * 获取供应商信息
   * @param params
   * @returns {AxiosPromise}
   */
  getSupplierInfo (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getSupplierInfo`,
      showLoading: true,
      params
    })
  }

  /**
   * 获取24个月产值数据
   * @param data
   * @returns {AxiosPromise}
   */
  getOutputValue (data) {
    return http({
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/getOutputValue`,
      showLoading: true,
      data
    })
  }

  /**
   * 获取24个月产值合计数据
   * @param params
   * @returns {AxiosPromise}
   */
  getSum (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getSum`,
      showLoading: true,
      params
    })
  }

  /**
   * 获取预授信银行
   * @param data
   * @returns {AxiosPromise}
   */
  getApplicableCapitalList (data) {
    return http({
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/getApplicableCapitalList`,
      showLoading: true,
      data
    })
  }

  /**
   * 申请预授信银行
   * @param data
   * @returns {AxiosPromise}
   */
  saveAuthorization (data) {
    return http({
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveAuthorization`,
      showLoading: true,
      data
    })
  }

  /**
   * 同步企业信息
   * @param data
   * @returns {AxiosPromise}
   */
  syncPreAuthorizationData (data) {
    return http({
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/syncPreAuthorizationData`,
      showLoading: true,
      data
    })
  }

  /**
   * 获取预申请详情
   * @param data
   * @returns {AxiosPromise}
   */
  getPreCreditDetails (data) {
    return http({
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/getPreCreditDetails`,
      showLoading: true,
      data
    })
  }

  /**
   * 正式授信列表
   * @param data
   * @returns {AxiosPromise}
   */
  getCreditResult (data) {
    return http({
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/getCreditResult`,
      showLoading: true,
      data
    })
  }

  /**
   * 获取融资付款单列表数据
   * @param data
   * @returns {AxiosPromise}
   */
  getCreditFinancingPage (data) {
    return http({
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/getCreditFinancingPage`,
      showLoading: true,
      data
    })
  }

  /**
   * 获取融资付款单列表数据
   * @param params
   * @returns {AxiosPromise}
   */
  getCreditFinDetail (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getCreditFinDetail`,
      showLoading: true,
      params
    })
  }

  /**
   * 获取融资付款单列表数据
   * @param params
   * @returns {AxiosPromise}
   */
  getPaymentProgress (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getPaymentProgress`,
      params
    })
  }

  /**
   * 付款单产值推送
   * @param data
   * @returns {AxiosPromise}
   */
  outputValuePush (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/outputValuePush`,
      data
    })
  }

  /**
   * 获取付款单金额数据等
   * @param params
   * @returns {AxiosPromise}
   */
  getClearingEchoData (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getClearingEchoData`,
      params
    })
  }

  /**
   * 还款清分消息推送
   * @param data
   * @returns {AxiosPromise}
   */
  repaymentClearancePush (data) {
    return http({
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/repaymentClearancePush`,
      data
    })
  }
}
